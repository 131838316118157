import CareCenterStageDisplay from '../images/case_studies/care-center-stage-mockup.png';
import BakUSADisplay from '../images/case_studies/bakusa-mockup.png';
import MagicPieDisplay from '../images/case_studies/magic-pie-mockup.png';
import TexAirDisplay from '../images/case_studies/tex-air-mockup.png';
import EquiptDisplay from '../images/case_studies/equipt-mockup.png';
import { Colors } from '../theme';

let CaseStudyData = [
  {
		title: 'Care Center Stage',
    snippet: 'See how we helped Tom turn his idea for a web app into a reality.',
    services: "Design, Development, Branding",
    date: '2021',
    buttonTitle: "See the work",
    displayImage: CareCenterStageDisplay,
    route: '/work/care-center-stage/',
    color: Colors.Aqua
  },
  {
		title: 'Bak USA',
    snippet: 'Bringing a startup to life through design.',
    services: "Website, Digital, Print, Branding, Advertising",
    date: '2016 - 2019',
    buttonTitle: "See the work",
    displayImage: BakUSADisplay,
    route: '/work/bakusa/',
    color: Colors.Pink
  },
  {
		title: 'Magic Pie Copywriting',
    snippet: 'Giving a copywriter what he deserves—a space for his writing to stand out.',
    services: "Branding, Design, Development",
    date: '2016 - 2019',
    buttonTitle: "Coming soon",
    displayImage: MagicPieDisplay,
    // route: '/work/bakusa/',
    color: Colors.Purple
  },
  {
		title: 'Tex-Air Gas Corp.',
    snippet: 'How we helped a third-generation helium distribution company join the digital era.',
    services: "Design, Development",
    date: '2020',
    buttonTitle: "Coming soon",
    displayImage: TexAirDisplay,
    // route: '/work/tex-air/',
    color: Colors.Pink
  },
  {
		title: 'Equipt Women',
    snippet: 'We helped a team of powerful women create a website to empower more women.',
    services: "Design",
    date: '2022',
    buttonTitle: "Coming soon",
    displayImage: EquiptDisplay,
    // route: '/work/tex-air/',
    color: Colors.Pink
  },
];

export { CaseStudyData };
