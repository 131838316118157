import React from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "../../theme";

import { Body, Header, SubHeader } from "../Typography";

const RadialMoveAnimation = keyframes`
  0% {
      background-position: 10% 10%;
  }
  50% {
      background-position: 90% 90%;
  }
  100% {
      background-position: 10% 10%;
  }
`;

const Wrapper = styled.div`
  max-width: 540px;
  min-height: 375px;
  position: relative;
  overflow: visible;
  /* border: ${({ color }) =>
    color ? "3px solid " + color : "3px solid " + Colors.Purple}; */
  background: ${({ color }) =>
    color
      ? "radial-gradient(circle farthest-side at 10% 10%, " +
        color +
        "7a ,rgba(0,178,255,.05) 72%)"
      : null};
  border-radius: 12px;
  padding: 32px;
  text-align: left;
  width: 23%;
  min-width: 300px;
  margin-bottom: 16px;
  animation: ${RadialMoveAnimation} 300ms infinite ease;
  animation-delay: ${({ index }) => index * 120 + "ms"};

  @media only screen and (max-width: 560px) {
  width: 100%;
  }
`;

const CircleWrapper = styled.div`
  width: 50px;
  height: 50px;
  border: ${({ color }) =>
    color ? "1px solid " + color : "1px solid " + Colors.Purple};
  border-radius: 50%;
  margin-bottom: 32px;
  text-align: center;
`;

const CardItem = styled.li`
  list-style-type: none;
`;

const ProcessCard = ({ data, key, index }) => {
  return (
    <Wrapper
      data-aos="fadeInUp"
      data-aos-duration={400}
      data-aos-delay={index * 400}
      key={key}
      color={data.color}
      index={index}
    >
      <CircleWrapper color={data.color}>
        <SubHeader large fontSize={32} margin={'6px 0 0 0'} letterSpacing={0}>{index + 1}</SubHeader>
      </CircleWrapper>
      <Header h3>{data.title}</Header>
      {data.subItems.map((item, index) => (
        <CardItem key={index}>
          <Body key={index}>
            {item}
          </Body>
        </CardItem>
      ))}
    </Wrapper>
  );
};

export default ProcessCard;
