import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme';
import { Header, SubHeader } from '../Typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 880px;
  min-width: 300px;
  margin: 0 auto;
`;

const AvatarGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 64px;
  text-align: left;

  @media only screen and (max-width: 860px) {
    flex-direction: column;
    text-align: center;
    gap: 24px;
  }
`;

const Image = styled.img`
  width: 80px !important;
  height: 80px;
  border-radius: 50%;
  transition: all 300ms ease;
  position: relative;
  border: 3px solid ${Colors.Purple} !important;
  padding: 8px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;

`;

const InlineWrapper = styled.div`
  display: flex;
`;

const Testimonial = (data) => {
  return (
    <Wrapper>
      <Header h4 sofia fontWeight={300}>{data.data?.quote}</Header>
      <AvatarGroupWrapper>
        <Image src={data.data?.image}/>
        <NameWrapper>
          <SubHeader medium thin={false}>{data.data?.name}</SubHeader>
          <InlineWrapper>
            <SubHeader thin={false}>{data.data?.title}</SubHeader>
            <SubHeader>&nbsp; | &nbsp;{data.data?.company}</SubHeader>
          </InlineWrapper>
        </NameWrapper>
      </AvatarGroupWrapper>
    </Wrapper>
  )
}

 export default Testimonial;
