import React from 'react';
import styled from 'styled-components';
import { Link }       from 'gatsby';
// import { Colors } from '../../theme';
import { NewLink } from '../Buttons';
import { Header, SubHeader } from '../Typography';
import BoxedImage from './BoxedImage';

const Wrapper = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: ${({rowReverse}) => rowReverse ? 'row-reverse' : 'row'};
  align-items: center;
  margin-bottom: 64px;
  transition: all 300ms ease;
  width: fit-content;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 48px;
  }

  &:hover {
      transform: scale(1.025);
    img {
      transform: scale(1.05);
    }
    .borderClass {
      opacity: 0.6;
      /* border: 3px solid; */
      border-radius: 10px;
      transform: translate(-50%, -50%) scale(.95);
    }

    .borderClass2 {
      opacity: 0.5;
      /* border: 2px solid; */
      border-radius: 10px;
      transform: translate(-50%, -50%) scale(.90);
    }

    .borderClass3 {
      opacity: 0.4;
      /* border: 2px solid; */
      border-radius: 9px;
      transform: translate(-50%, -50%) scale(.85);
    }
    .borderClass4 {
      opacity: 0.3;
      /* border: 2px solid; */
      border-radius: 8px;
      transform: translate(-50%, -50%) scale(.80);
    }
    .borderClass5 {
      opacity: 0.2;
      /* border: 2px solid; */
      border-radius: 7px;
      transform: translate(-50%, -50%) scale(.75);
    }
    .borderClass6 {
      opacity: 0.1;
      /* border: 2px solid; */
      border-radius: 6px;
      transform: translate(-50%, -50%) scale(.70);
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 400px;
  margin-left: 64px;
  gap: 8px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
`;

const CaseStudyCard = ({data, rowReverse, snippet}) => {
  return (
    <Link to={data.route}>
      <Wrapper rowReverse={rowReverse}>
        <BoxedImage src={data.displayImage} color={data.color}/>
        <ContentWrapper>
          <SubHeader>{data.services}</SubHeader>
          <Header h2={!snippet} h3={snippet} sofia>{snippet ? data.snippet : data.title}</Header>
          <div style={{ height: "16px" }}></div>
          <NewLink to={data.route} color={data.color} disabled={!data.route}>{data.buttonTitle}</NewLink>
        </ContentWrapper>
      </Wrapper>
    </Link>
  )
}

 export default CaseStudyCard;
